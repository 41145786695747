<template>
    <div class="com-header">
        <zj-header-logo :title="title" :subtitle="subtitle" :logo-url="logoUrl"/>
        <!-- <zj-header-menu /> -->
        
    </div>
</template>

<script>
import {ZjHeaderLogo, ZjHeaderMenu} from 'zj-header';
export default {
    name: 'ComHeader',
    props: {
    },
    components: {
        ZjHeaderLogo, 
        ZjHeaderMenu,
    },
    data(){
        return {
            title: "变电站通流回路设备温度监测预警系统",
            subtitle: 'Muztak Micro Thermoelect Temperature Unit 微温差电温度传感器可视化管理中心',
            logoUrl: require('@/static/images/logo-sgcc-white.svg'),
        }
    },
    computed:{
    },
    methods: {
        onSettings(){
            if(!this.$store)
                return;
            if(this.$store.state.modWindows['comViewsPanel'].is_show)
                this.$store.dispatch('closeWindow', 'comViewsPanel');
            else
                this.$store.dispatch('showWindow', 'comViewsPanel');
        },
        onMenu(key){
            // console.log("onMenu: " , key)
            this.$store.dispatch('showWindow', key);
        },
        onMapType(type){
            this.mapType = type;
            this.$store.dispatch('setMapType', type);
        },
        onZoom(value){
            console.log("onZoom: " , value)
            this.$store.dispatch('setMapZoom', value);
        },
        onQuit(value){
            this.$store.dispatch('setToken', '');
            this.$router.push({path: "/login"});
        },
    },
}
</script>

<style>
.com-header{display: flex; padding: 0 0 0 10px; height: 80px; justify-content: space-between; align-items: center; background: rgba(0, 121, 106, 0.8);
;}
.com-header .title-bar{}
</style>